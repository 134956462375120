import React from "react";
import { Helmet } from "react-helmet";

export default function Pricing(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Pricing - Affordable LLMOps & GenAIOps Solutions</title>
        <meta
          name="description"
          content="Discover Dataster's competitive pricing for LLMOps & GenAIOps solutions."
        />
        <link rel="canonical" href="https://www.dataster.com/pricing/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Pricing</h1>
        <span>
          Dataster helps you build Generative AI applications with better
          accuracy and lower latency.
        </span>
        <div className="page-container__pricing">
          <div className="page-container__pricing__desc">
            <h2>Pricing</h2>
            <div>
              <p>
                <strong>
                  Dataster offers a straightforward pricing model based on a
                  metering mechanism.
                </strong>{" "}
                Users purchase prepaid credits in $25 increments. Dataster
                meters input and output tokens, charging a small fee against
                your prepaid credit based on actual consumption. Charges vary
                depending on the provider and model used. Dataster provides
                direct access to a catalog of models from different providers,
                but users may also choose to bring their own models (BYO)
                instead of or in addition to Dataster-provided models. In the
                case of BYO, Dataster's charge is much smaller, and users pay
                the model provider directly based on the existing agreement tied
                to their API key.
              </p>
              <br />
              <p>
                Some Dataster features and functionalities, such as{" "}
                <strong>Prompt Critique</strong> or <strong>Auto Eval</strong>,
                rely on internal Dataster LLMs and also decrement credit.
              </p>
              <br />
              <p>
                The detailed price table can be found below. Pricing may change.
              </p>
            </div>
          </div>

          <div className="page-container__pricing__desc">
            <h2>Try for free</h2>
            <div>
              <p>
                New users receive a $1 free tier credit upon signup, allowing
                them to test all features and functionalities. No credit card is
                required.
              </p>
            </div>
          </div>

          <div className="page-container__pricing__desc">
            <h2>Price table for Completions</h2>
            <p>For 1 million tokens.</p>
            <div style={{ width: "100%" }}>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <table class="pretty-table">
                  <thead>
                    <tr>
                      <th>Provider</th>
                      <th>Model</th>
                      <th>Dataster Input</th>
                      <th>Dataster Output</th>
                      <th>BYO Input*</th>
                      <th>BYO Output*</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Azure</td>
                      <td>GPT-3.5-Turbo-0125</td>
                      <td>$0.90</td>
                      <td>$2.70</td>
                      <td>$0.30</td>
                      <td>$0.90</td>
                    </tr>

                    <tr>
                      <td>Azure</td>
                      <td>GPT-4o-2024-05-13</td>
                      <td>$9.00</td>
                      <td>$27.00</td>
                      <td>$3.00</td>
                      <td>$9.00</td>
                    </tr>

                    <tr>
                      <td>Azure</td>
                      <td>GPT-4o-2024-08-06</td>
                      <td>$4.95</td>
                      <td>$19.80</td>
                      <td>$1.65</td>
                      <td>$6.60</td>
                    </tr>

                    <tr>
                      <td>AWS</td>
                      <td>Claude 3 Sonnet</td>
                      <td>$5.40</td>
                      <td>$27.00</td>
                      <td>$1.80</td>
                      <td>$9.00</td>
                    </tr>

                    <tr>
                      <td>AWS</td>
                      <td>Llama 3 Instruct (8B)</td>
                      <td>$0.54</td>
                      <td>$1.08</td>
                      <td>$0.18</td>
                      <td>$0.36</td>
                    </tr>

                    <tr>
                      <td>Azure</td>
                      <td>text-embedding-3-small</td>
                      <td>$0.04</td>
                      <td>N/A</td>
                      <td>$0.01</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Azure</td>
                      <td>text-embedding-3-large</td>
                      <td>$0.23</td>
                      <td>N/A</td>
                      <td>$0.08</td>
                      <td>N/A</td>
                    </tr>

                    <tr>
                      <td>Azure</td>
                      <td>GPT-4o-mini-2024-07-18</td>
                      <td>$0.30</td>
                      <td>$1.19</td>
                      <td>$0.10</td>
                      <td>$0.40</td>
                    </tr>

                    <tr>
                      <td>AWS</td>
                      <td>Claude 3 Haiku</td>
                      <td>$0.45</td>
                      <td>$2.25</td>
                      <td>$0.15</td>
                      <td>$0.75</td>
                    </tr>

                    <tr>
                      <td>Open AI</td>
                      <td>GPT-4o-2024-08-06</td>
                      <td>$4.95</td>
                      <td>$19.80</td>
                      <td>$1.65</td>
                      <td>$6.60</td>
                    </tr>

                    <tr>
                      <td>Open AI</td>
                      <td>GPT-4o-mini-2024-07-18</td>
                      <td>$0.30</td>
                      <td>$1.19</td>
                      <td>$0.10</td>
                      <td>$0.40</td>
                    </tr>

                    <tr>
                      <td>AWS</td>
                      <td>Jamba 1.5 Mini</td>
                      <td>$0.36</td>
                      <td>$0.72</td>
                      <td>$0.12</td>
                      <td>$0.24</td>
                    </tr>

                    <tr>
                      <td>AWS</td>
                      <td>Jamba 1.5 Large</td>
                      <td>$3.60</td>
                      <td>$14.40</td>
                      <td>$1.20</td>
                      <td>$4.80</td>
                    </tr>

                    <tr>
                      <td>AWS</td>
                      <td>Cohere Command R+</td>
                      <td>$5.40</td>
                      <td>$27.00</td>
                      <td>$1.80</td>
                      <td>$9.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                *Amounts in the BYO columns represent only the Dataster charge
                for requests sent to a user-provided model. Users may incur
                additional fees from their providers based on their existing
                agreement.
              </p>
            </div>
          </div>

          <div className="page-container__pricing__desc">
            <h2>Other Capabilities</h2>

            <p>
              The Dataster built-in capabilities are billed as follows for 1
              million tokens.
            </p>
            <div>
              <table class="pretty-table">
                <thead>
                  <tr>
                    <th>Capability</th>
                    <th>Input</th>
                    <th>Output</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Prompt Critique</td>
                    <td>$4.95</td>
                    <td>$19.80</td>
                  </tr>
                  <tr>
                    <td>Auto Eval</td>
                    <td>$4.50</td>
                    <td>$18</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="page-container__pricing__desc">
            <h2>Refunds</h2>
            <div>
              <p>
                Prepaid credit is purchased in $25 increments. Dataster cannot
                refund partially used increments. However, Dataster will refund
                unused increments minus a 10% fee to cover processing costs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
