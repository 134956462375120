import { Helmet } from "react-helmet";

export default function About(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster News - Latest Updates & Feature Announcements</title>
        <meta
          name="description"
          content="Stay informed with the latest news and updates on GenAIOps & LLMOps. Read about our software updates, new features, and capabilities in LLMOps & GenAIOps for AI developers."
        />
        <link rel="canonical" href="https://www.dataster.com/news/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster News</h1>
        <span>
          Dataster helps you build Generative AI applications with better
          accuracy and lower latency.
        </span>
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <span style={{ fontSize: "larger", textAlign: "right" }}>
              December 2024
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Prompt catalog update</h2>
            </div>
            <div>
              <p>
                We are dedicated to creating the most versatile and feature-rich
                prompt gallery available. Now, you have the power to re-order
                your prompts, allowing you to present them in the sequence that
                best suits your needs, rather than the order they were created
                in. Experience the freedom to customize your workflow like never
                before!
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Bias reduction and randomization</h2>
            </div>
            <div>
              <p>
                In the realm of LLM and RAG output evaluation by humans,
                minimizing bias is crucial, if not eliminating it entirely. It's
                easy to develop a preference for a specific model or provider,
                but this can skew the objectivity of the evaluation process.
                Dataster offers robust bias removal mechanisms for human
                evaluation. Historically, it has been possible to mask the
                model, RAG, and even the system prompt used to generate a
                particular output. With today's release, we introduce an
                additional layer of bias reduction by randomizing the order in
                which input and output pairs are presented to evaluators. This
                makes it virtually impossible to determine which model, RAG, or
                system prompt produced the output until the entire evaluation is
                complete. Happy blind testing!
              </p>
            </div>
          </div>
        </div>
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <span style={{ fontSize: "larger", textAlign: "right" }}>
              November 2024
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Cohere Command R+ on AWS is now on Dataster</h2>
            </div>
            <div>
              <p>
                We're continuing our Large Language Model catalog expansion with
                the addition of <code>cohere.command-r-plus-v1:0</code> as an
                AWS hosted option; bring your own or use ours. Sign in, send
                some tokens and try it out!
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Jamba 1.5 Large on AWS is now on Dataster</h2>
            </div>
            <div>
              <p>
                Last month, we introduced our first A21 Large Language Model
                with Jamba 1.5 Mini. Building on this momentum, we are excited
                to announce the addition of{" "}
                <code>ai21.jamba-1-5-large-v1:0</code> to our catalog! This
                model is now available as an AWS-hosted option. As always, you
                have the flexibility to bring your own AWS-hosted Jamba 1.5
                Large if you prefer. Give it a try and see what it can do!
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Bulk deletion of prompts</h2>
            </div>
            <div>
              <p>
                For specific sensitive use cases, users may want to delete their
                prompts from the database. We're introducing a feature that
                allows for the easy deletion of a large collection of prompts
                from the catalog and the underlying data store, while retaining
                the ones you wish to keep. Deleting a prompt or bulk deleting
                prompts will effectively remove all text and associated metadata
                from our database instantly. However, prompt content may still
                appear in our secure logs for up to seven days.
              </p>
            </div>
          </div>
        </div>
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <span style={{ fontSize: "larger", textAlign: "right" }}>
              October 2024
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Jamba 1.5 Mini on AWS is now on Dataster</h2>
            </div>
            <div>
              <p>
                Today, we're thrilled to unveil the latest addition to our model
                catalog: <code>ai21.jamba-1-5-mini-v1:0</code>! This
                cutting-edge language model is now available as an AWS-hosted
                option. Plus, you still have the flexibility to bring your own
                AWS-hosted Jamba 1.5 Mini if you prefer. Dive in and explore the
                possibilities!
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>OpenAI GPT-4o-mini is now on Dataster</h2>
            </div>
            <div>
              <p>
                We're excited to announce the addition of another powerful
                language model to our collection:{" "}
                <code>gpt-4o-mini-2024-07-18</code>. As always, you have the
                flexibility to bring your own OpenAI GPT-4o mini if you prefer.
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Language Models and RAG Auto evaluation</h2>
            </div>
            <div>
              <p>
                Dataster has a human evaluation feature where users can rate a
                list of completions as good or bad, or alternatively, on a scale
                from 1 to 5. We are excited to release a new capability in beta
                that automates the binary evaluation process. Dataster can now
                automatically rate all your candidate models and RAGs against
                your use case, helping you make the best decisions when building
                your GenAI-backed application.
              </p>
            </div>
          </div>

          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>User Prompt and Ground Truth</h2>
            </div>
            <div>
              <p>
                We are adding the capability to optionally save a ground truth
                answer corresponding to a user question in a user prompt. This
                will underpin our upcoming Auto Eval feature, which will allow
                you to launch automated evaluation jobs to determine how your
                RAGs perform against a large and representative set of user
                questions. This will supplement the existing Human Eval feature,
                which lets users manually assign grades to each response and
                rank their models and RAGs for a specific use case.
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>OpenAI on Dataster</h2>
            </div>
            <div>
              <p>
                We are adding support for <code>gpt-4o-2024-08-06</code> hosted
                on OpenAI. This model was previously supported on Dataster only
                as an Azure OpenAI hosted model. Users can now compare the
                model’s performance across both providers. With this release,
                Dataster now supports 10 models across three different
                providers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
